<script>
export default {
  name: "MainForm",

  emits: ["close"],

  data() {
    return {
      selectTab: "adventures",
      name: "",
      phone: "",
      description: "",
      telegram: "",
    };
  },

  beforeMount() {
    this.selectTab = this.$route.name;
  },
};
</script>

<template>
  <div class="main-form">
    <div class="main-form__wrapper">
      <button type="button" @click="$emit('close')">
        <img src="@/assets/icons/close.svg" alt="close" />
      </button>

      <h3>Регистрация</h3>

      <p v-if="selectTab === 'adventures'">
        Оставьте свои контактные данные и в ближайшее время мы с Вами свяжемся
      </p>

      <div class="tabs">
        <button
          class="tab"
          :class="{ tab_active: selectTab === 'adventures' }"
          type="button"
          @click="selectTab = 'adventures'"
        >
          Рекламодателям
        </button>
        <button
          class="tab"
          :class="{ tab_active: selectTab === 'publisher' }"
          type="button"
          @click="selectTab = 'publisher'"
        >
          Веб-мастерам
        </button>
      </div>

      <form @submit.prevent>
        <fieldset>
          <input type="text" placeholder="Имя" v-model="name" />
          <input type="text" placeholder="Телефон" v-model="phone" />
          <input
            type="text"
            placeholder="Telegram"
            v-if="selectTab === 'publisher'"
            v-model="telegram"
          />
          <textarea
            v-else
            placeholder="Описание продукта"
            v-model="description"
          />
        </fieldset>
        <button type="submit">Отправить</button>
      </form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-form {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    align-items: flex-end;
  }

  &__wrapper {
    padding: ($base-space * 8);
    border-radius: 10px;
    background-color: $grey-4;
    position: relative;
    width: 500px;
    overflow-y: auto;
    max-height: 100vh;

    @media (max-width: 767px) {
      width: 100%;
      padding: ($base-space * 8) ($base-space * 4);
      border-radius: 20px 20px 0px 0px;
      background-color: $grey-4;
    }

    > button {
      position: absolute;
      width: 28px;
      height: 28px;
      top: 32px;
      right: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 767px) {
        top: 16px;
        right: 16px;
      }
    }

    > h3 {
      @include h2();
      color: $white;
    }

    > p {
      margin: ($base-space * 3) 0 0;
      @include body-1();
      color: $grey-2;
    }

    > form {
      margin: ($base-space * 8) 0 0;

      > fieldset {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: $base-space * 5;

        @media (max-width: 1023px) {
          grid-gap: $base-space * 4;
        }

        > input {
          border-radius: 6px;
          background-color: $white;
          padding: ($base-space * 3) ($base-space * 4);
          @include body-2();
          border: 1px solid $white;
          outline: none;
          color: $black;

          &:hover {
            background-color: $grey-1;
            border: 1px solid $grey-1;
          }

          &:focus {
            border: 1px solid $orange-1;
            background-color: $white;
          }

          &:focus:hover {
            border: 1px solid $orange-1;
            background-color: $white;
          }

          &::placeholder {
            color: $grey-3;
          }
        }

        > textarea {
          border-radius: 6px;
          background-color: $white;
          padding: ($base-space * 3) ($base-space * 4);
          @include body-2();
          border: 1px solid $white;
          outline: none;
          color: $black;
          resize: none;
          height: 100px;

          &:hover {
            background-color: $grey-1;
            border: 1px solid $grey-1;
          }

          &:focus {
            border: 1px solid $orange-1;
            background-color: $white;
          }

          &:focus:hover {
            border: 1px solid $orange-1;
            background-color: $white;
          }

          &::placeholder {
            color: $grey-3;
          }
        }
      }

      > button {
        padding: ($base-space * 4) ($base-space * 8);
        margin: ($base-space * 8) 0 0;
        border-radius: 6px;
        background-color: $orange-1;
        color: $white;
        @include body-1();
        transition: all 0.3s ease-in;
        width: 100%;

        @media (max-width: 1023px) {
          padding: ($base-space * 3) ($base-space * 4);
        }

        &:hover {
          background-color: $orange-2;
        }
      }
    }
  }
}

.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $base-space * 2;
  border-radius: 8px;
  background-color: $white;
  padding: ($base-space * 2);
  margin: ($base-space * 8) 0 0;

  > .tab {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: ($base-space * 4) ($base-space * 8);
    @include body-1();
    color: $black;
    border-radius: 6px;
    background-color: $grey-1;
    transition: all 0.3s ease-in;

    @media (max-width: 1023px) {
      padding: ($base-space * 2) ($base-space * 4);
    }

    &:hover {
      background-color: $grey-2;
    }

    &_active {
      background-color: $orange-1;
      color: $white;

      &:hover {
        background-color: $orange-1;
      }
    }
  }
}
</style>
