<script>
import MobileMenu from "@/components/MobileMenu.vue";
import MainForm from "@/components/MainForm.vue";

export default {
  name: "App",
  components: { MainForm, MobileMenu },

  data() {
    return {
      navigation: [
        { name: "Главная", path: "home" },
        { name: "О нас", path: "about" },
        { name: "Рекламодателям", path: "adventures" },
        { name: "Веб-мастерам", path: "publisher" },
        { name: "Контакты", path: "contacts" },
      ],

      showMobileMenu: false,
      showForm: false,
      windowWidth: 0,
    };
  },

  methods: {
    setPageWidth() {
      this.windowWidth = window.innerWidth;
    },
  },

  computed: {
    showFormButton() {
      if (
        this.$route.name === "adventures" ||
        this.$route.name === "publisher"
      ) {
        return true;
      }
      return false;
    },
  },

  created() {
    window.addEventListener("resize", this.setPageWidth);
    this.setPageWidth();
  },

  unmounted() {
    window.removeEventListener("resize", this.setPageWidth);
  },
};
</script>

<template>
  <MobileMenu
    :navigation="navigation"
    @close="showMobileMenu = false"
    v-if="showMobileMenu && windowWidth < 768"
  />

  <MainForm v-if="showForm" @close="showForm = false" />

  <header class="container header">
    <img
      src="@/assets/logo.svg"
      alt="logotype"
      v-motion
      :initial="{
        opacity: 0,
      }"
      :enter="{
        opacity: 1,
        transition: {
          duration: 300,
          type: 'keyframes',
          ease: 'ease',
        },
      }"
    />

    <button type="button" @click="showForm = true" v-if="showFormButton">
      Регистрация
    </button>
  </header>
  <router-view />

  <footer
    class="footer"
    v-motion
    :initial="{
      opacity: 0,
      y: 300,
    }"
    :enter="{
      opacity: 1,
      y: 0,
      transition: {
        duration: 600,
        type: 'keyframes',
        ease: 'ease',
      },
    }"
  >
    <nav>
      <router-link
        :to="{ name: item.path }"
        v-for="(item, index) in navigation"
        :key="index"
      >
        {{ item.name }}
      </router-link>

      <button type="button" @click="showMobileMenu = true">Меню</button>
    </nav>
  </footer>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    @media (max-width: 1023px) {
      width: 120px;
    }
  }

  > button {
    padding: ($base-space * 4) ($base-space * 8);
    border-radius: 6px;
    background-color: $orange-1;
    color: $white;
    @include body-1();
    transition: all 0.3s ease-in;

    @media (max-width: 1023px) {
      padding: ($base-space * 3) ($base-space * 4);
    }

    &:hover {
      background-color: $orange-2;
    }
  }
}

.footer {
  position: fixed;
  bottom: 80px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1023px) {
    bottom: 40px;
  }

  > nav {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $base-space * 2;
    margin: 0 auto;
    border-radius: 8px;
    background-color: $white;
    padding: ($base-space * 2);

    @media (max-width: 767px) {
      width: calc(100% - 32px);
    }

    > a {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: ($base-space * 4) ($base-space * 8);
      @include body-1();
      color: $black;
      border-radius: 6px;
      background-color: $grey-1;
      transition: all 0.3s ease-in;

      &:hover {
        background-color: $grey-2;
      }

      @media (max-width: 1439px) {
        width: auto;
      }

      @media (max-width: 767px) {
        display: none;
      }

      &.router-link-active {
        background-color: $orange-1;
        color: $white;

        &:hover {
          background-color: $orange-1;
        }
      }
    }

    > button {
      padding: ($base-space * 3) ($base-space * 4);
      border-radius: 6px;
      width: 100%;
      background-color: $orange-1;
      color: $white;
      @include body-1();
      display: none;
      transition: all 0.3s ease-in;

      &:hover {
        background-color: $orange-2;
      }

      @media (max-width: 767px) {
        display: block;
      }
    }
  }
}
</style>
